@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-100;
}

h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}
a {
  @apply text-blue-600;
}

.datasheet {
  --dsg-selection-border-radius: 10px;
}

.form-input-styles {
  @apply mt-1 block rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm w-full sm:w-3/4 mb-4;
}
